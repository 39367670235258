<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form :method.sync="method" :options="options">

      <div>
        <div class="form-row">
          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12 col-sm-4"
            label="รหัส"
            v-model="formData.code"
            :validations="[
              {text: 'required!', value: $v.formData.code.$dirty && $v.formData.code.$error}
            ]">
          </sgv-input-text>

          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12 col-sm-8"
            label="ชื่อ"
            v-model="formData.name">
          </sgv-input-text>

          <DetailFormDropdownParent
            :disabled="$auth.disabled(method)"
            label="parent"
            class="col-12"
            select="id"
            :manageType="manageType"
            :templateType="templateType"
            v-model="formData.parentId">
          </DetailFormDropdownParent>
        </div>

        <CategoryCheck
          :disabled="$auth.disabled(method)"
          :manageType="manageType"
          :templateType="templateType"
          :method="method"
          v-model="formData.categories">
        </CategoryCheck>
      </div>
    </sgv-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  DETAIL_MANAGE,
  CREATE_MANAGE,
  UPDATE_MANAGE,
  DESTROY_MANAGE
} from './graph'
import DetailFormDropdownParent from './DetailFormDropdownParent.vue'
import CategoryCheck from './CategoryCheck.vue'

export default {
  metaInfo () {
    if (this.formData.code) {
      return {title: `${this.formData.code}-${this.formData.name}`}
    }
    return {title: `${this.title}`}
  },
  props: {
    manageId: {
      type: Number,
      required: true
    },
    manageType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      listView: `Manage${this.$form.capitalize(this.manageType)}List`,
      formData: {
        code: '',
        name: '',
        parentId: null,
        categories: []
      },
      method: 'info'
    }
  },
  validations: {
    formData: {
      code: { required }
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`manage:${this.manageType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`manage:${this.manageType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_MANAGE(this.templateType),
        variables: {
          manageType: this.manageType,
          manageId: this.manageId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.manage)})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        }
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const obj = {...v}
      delete obj.addresses
      return obj
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_MANAGE(this.templateType),
        variables: {
          manageType: this.manageType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, manageId: res.data.createManage.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_MANAGE(this.templateType),
        variables: {
          manageType: this.manageType,
          manageId: this.manageId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateManage)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_MANAGE(this.templateType),
        variables: {
          manageType: this.manageType,
          manageId: this.manageId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.listView) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.manageId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailFormDropdownParent,
    CategoryCheck
  }
}
</script>

<style lang="css" scoped>
</style>
